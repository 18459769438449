@import '../../../shared';

@mixin use-gradient-1($top: auto, $right: auto, $bottom: auto, $left: auto) {
  $image: './../assets/images/hub/siteBuilder/modules/gradient-1';
  $width: 700px;
  $height: 446px;

  @include use-gradient($image, $width, $height, $top, $right, $bottom, $left);
}

@mixin use-gradient-2($top: auto, $right: auto, $bottom: auto, $left: auto) {
  $image: './../assets/images/hub/siteBuilder/modules/gradient-2';
  $width: 852px;
  $height: 714px;

  @include use-gradient($image, $width, $height, $top, $right, $bottom, $left);
}

@mixin use-gradient-3($top: auto, $right: auto, $bottom: auto, $left: auto) {
  $image: './../assets/images/hub/siteBuilder/modules/gradient-3';
  $width: 1074px;
  $height: 600px;

  @include use-gradient($image, $width, $height, $top, $right, $bottom, $left);
}

.hub-module-dark-theme {
  &.hub-hero-module
    > :not(.hub-hero-module-image-display-style):not(
      .hub-hero-module-video-display-style
    ):not(.hub-hero-module-multiple-images-display-style) {
    .hub-module-styled-element-1 {
      @include mq($from: desktop) {
        @include use-gradient-3($top: 0, $left: 0);
        margin-top: -50px;
        margin-left: -50px;
      }
    }

    .hub-module-styled-element-2 {
      @include mq($from: desktop) {
        @include use-gradient-3($bottom: 0, $right: 0);
        margin-bottom: -200px;
        margin-right: -400px;
        transform: rotate(180deg);
      }
    }
  }

  &.hub-accordion-module {
    .hub-module-styled-element-1 {
      @include use-gradient-1($bottom: 0, $left: 0);
    }

    .hub-module-styled-element-2 {
      @include use-gradient-2($top: 0, $right: 0);
    }
  }

  &.hub-alert-module {
    .hub-module-styled-element-1 {
      @include use-gradient-1($bottom: 0, $left: 0);
      margin-bottom: -50px;
      margin-left: -400px;
    }

    .hub-module-styled-element-2 {
      @include use-gradient-2($top: 0, $right: 0);
      margin-top: -250px;
      margin-right: -50px;
    }
  }

  &.hub-custom-text-section-module {
    .hub-module-styled-element-1 {
      @include use-gradient-1($bottom: 0, $left: 0);
    }

    .hub-module-styled-element-2 {
      @include use-gradient-2($bottom: 0, $right: 0);
      margin-bottom: -300px;
    }

    .hub-module-styled-element-3 {
      @include use-gradient-1($top: 0, $left: 0);
      margin-top: -80px;
      margin-left: -300px;
    }
  }

  &.hub-file-download-module {
    .hub-module-styled-element-1 {
      @include use-gradient-1($bottom: 0, $left: 0);
    }

    .hub-module-styled-element-2 {
      @include use-gradient-2($bottom: 0, $right: 0);
      margin-bottom: -200px;
    }
  }

  &.hub-image-module {
    .hub-module-styled-element-1 {
      @include use-gradient-1($bottom: 0, $left: 0);
      margin-bottom: -100px;
    }

    .hub-module-styled-element-2 {
      @include use-gradient-2($top: 0, $right: 0);
      margin-top: -250px;
    }
  }

  &.hub-statistics-module {
    .hub-module-styled-element-1 {
      @include use-gradient-1($bottom: 0, $left: 0);
      margin-bottom: -250px;
    }

    .hub-module-styled-element-2 {
      @include use-gradient-2($bottom: 0, $right: 0);
      margin-bottom: -100px;
    }
  }

  &.hub-testimonial-module {
    .hub-module-styled-element-1 {
      @include use-gradient-1($bottom: 0, $left: 0);
      margin-bottom: -150px;
    }

    .hub-module-styled-element-2 {
      @include use-gradient-2($bottom: 0, $right: 0);
      margin-bottom: -100px;
    }
  }

  &.hub-video-module {
    .hub-module-styled-element-2 {
      @include use-gradient-2($bottom: 0, $right: 0);
      margin-bottom: -100px;
    }
  }
}
