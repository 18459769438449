@import '../Default/theme';
@import '../main/ic-gradient';

:root {
  /**
   * Hub Carousel Module
   */
  --hub-carousel-module-background-color: radial-gradient(
      287.29% 123.68% at 5.32% 3.44%,
      rgba(176, 26, 164, 0.3) 0%,
      rgba(176, 26, 164, 0) 100%
    ),
    #024;
  --hub-carousel-module-slide-background-color: radial-gradient(
      120.63% 120.63% at 5.77% -20.63%,
      rgba(176, 26, 164, 0.5) 10.67%,
      rgba(176, 26, 164, 0) 100%
    ),
    #024;
}
